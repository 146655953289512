import ng from 'angular';
import { acpDigitalWalletDomainModule } from 'apps/digital-wallet/components/digital-wallet-domain';
import acpCore from 'core';
import { acpDigitalWalletInformationComponent } from './acp-digital-wallet-information-component';
import acpDigitalWalletContent from './digital-wallet-content.yml';
import './styles/${theme}/core.scss';

export const acpDigitalWalletModule = ng
  .module('acp.component.digital-wallet', [
    acpCore.name,
    acpDigitalWalletDomainModule.name
  ])
  .component(
    'acpDigitalWalletInformation',
    acpDigitalWalletInformationComponent
  )
  .run((contentSectionCache) => {
    'ngInject';
    contentSectionCache.put(
      'components/digital-wallet',
      acpDigitalWalletContent
    );
  });

export * from './acp-digital-wallet-information-component';
export { default as acpDigitalWalletContent } from './digital-wallet-content.yml';
