import { AcpDigitalWalletDomainModel } from 'apps/digital-wallet/components/digital-wallet-domain';
import { CardResponse } from 'components/card-domain';
import { AcpAnalytics } from 'core';
import acpDigitalWalletInformationTemplate from './templates/acp-digital-wallet-information.html';

export class AcpDigitalWalletInformationComponentCtrl
  implements nsUtils.NsComponentController {
  public paymentPlatform: string;
  public convenientIcon: any;
  public digitalPaymentIcon: any;
  public secureIcon: any;
  public isGooglePay: boolean;
  public isApplePay: boolean;
  public isSamsungPay: boolean;
  private cards: CardResponse[] = [];
  private GA_CATEGORY: string = 'ACP_Digital_Wallets_Push_Provisioning';

  constructor(
    private acpDigitalWalletDomainModel: AcpDigitalWalletDomainModel,
    private $log: ng.ILogService,
    private $state: any,
    private $mdDialog: ng.material.IDialogService,
    private nsInPageFlow: any,
    private acpAnalytics: AcpAnalytics
  ) {
    'ngInject';
  }

  public async $onInit(): Promise<void> {
    this.acpAnalytics.sendEvent(
      this.GA_CATEGORY,
      'Viewed_Landing_page',
      'acp-landing'
    );
    try {
      const cardsResponse = await this.acpDigitalWalletDomainModel.getCards();
      this.cards = cardsResponse.cards;
    } catch (e) {
      this.$log.error(e);
    }
    try {
      this.paymentPlatform = await this.acpDigitalWalletDomainModel.getPaymentPlatformsFormatted();
    } catch (e) {
      this.$state.go('dashboard');
    }
    this.isGooglePay = await this.acpDigitalWalletDomainModel.isGooglePay();
    this.isApplePay = await this.acpDigitalWalletDomainModel.isApplePay();
    this.isSamsungPay = await this.acpDigitalWalletDomainModel.isSamsungPay();
  }

  public goToCards(): void {
    const cardExp = /^card/gi;
    this.acpAnalytics.sendEvent(
      this.GA_CATEGORY,
      'Clicked_Go_To_Cards',
      'acp-landing'
    );
    if (cardExp.test(this.$state.current.name)) {
      // if this was opened via the info icon in card details
      this.$mdDialog.hide();
      if (this.cards.length >= 2) {
        this.nsInPageFlow.close();
      }
      return;
    }
    // < 2 in case the webapi call hasn't completed
    // it will at least attempt the check in the card ctrl

    this.$state.go('card', {
      showDetail: this.cards.length < 2
    });
  }
}

export const acpDigitalWalletInformationComponent: ng.IComponentOptions = {
  controller: AcpDigitalWalletInformationComponentCtrl,
  controllerAs: 'vm',
  template: acpDigitalWalletInformationTemplate
};
